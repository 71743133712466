import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { LooseAutocomplete } from "../../pojo/Util";
import { VariableI } from "../../pojo/VariableI";

@Component({
    selector: "s25-ng-list-builder-variable",
    template: `
        <s25-bpe-vars-dropdown
            class="c-margin-top--quarter"
            [(chosen)]="listBuilder.type"
            type="tables"
            (chosenChange)="onListTypeSelect()"
        ></s25-bpe-vars-dropdown>
        @if (listBuilder.type?.val && listBuilder.type.val !== "none") {
            <s25-bpe-vars-dropdown
                [type]="$any(listBuilder.type.val)"
                [(chosen)]="listBuilder.variable"
                (chosenChange)="onListVariableChange()"
            ></s25-bpe-vars-dropdown>
        }
        @if (listBuilder.variable?.hasFormat) {
            <label>
                <span class="selectedVarLabel">Format: </span>
                <s25-bpe-vars-dropdown
                    type="tableDateFormats"
                    [(chosen)]="listBuilder.format"
                    (chosenChange)="updateListVar()"
                ></s25-bpe-vars-dropdown>
            </label>
        }
        @if (listBuilder.type?.val && listBuilder.variable?.val && listBuilder.templateVariable) {
            <div>
                <s25-ng-copy-variable [label]="'List Variable'" [text]="listBuilder.templateVariable" />
                @if (type !== "document") {
                    <s25-ng-copy-variable [label]="'Pre Variable'" [text]="listBuilder.templatePreVariable" />
                }
                @if (listBuilder.hasPref) {
                    <s25-ng-copy-variable [label]="'Pref Variable'" [text]="listBuilder.templatePrefVariable" />
                }
                @if (listBuilder.hasPref && type !== "document") {
                    <s25-ng-copy-variable [label]="'PrePref Variable'" [text]="listBuilder.templatePrePrefVariable" />
                }
            </div>
        }
    `,
    styles: ``,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ListBuilderVariableComponent {
    @Input({ required: true }) type: LooseAutocomplete<"email"> = "email";

    listBuilder: {
        type?: VariableI;
        variable?: VariableI;
        format?: VariableI;
        hasPref?: boolean;
        templateVariable?: string;
        templatePreVariable?: string;
        templatePrefVariable?: string;
        templatePrePrefVariable?: string;
    } = {};

    constructor(private cd: ChangeDetectorRef) {}

    onListTypeSelect() {
        this.listBuilder = {
            type: this.listBuilder.type,
            hasPref: ["locations", "resources"].includes(this.listBuilder.type.val),
        };
        this.updateListVar();
        this.cd.detectChanges();
    }

    onListVariableChange() {
        delete this.listBuilder.format;
        this.updateListVar();
    }

    updateListVar() {
        const key = this.listBuilder.type.val;
        const Key = key.slice(0, 1).toUpperCase() + key.slice(1);
        const variable = this.listBuilder.variable?.val;
        const format = this.listBuilder.format?.val ? `| ${this.listBuilder.format?.val} ` : "";
        this.listBuilder.templateVariable = `{{list: $pro.vars.${key} : ${variable} ${format}}}`;
        this.listBuilder.templatePreVariable = `{{list: $pro.vars.pre${Key} : ${variable} ${format}}}`;
        this.listBuilder.templatePrefVariable = `{{list: $pro.vars.pref${Key} : ${variable} ${format}}}`;
        this.listBuilder.templatePrePrefVariable = `{{list: $pro.vars.prePref${Key} : ${variable} ${format}}}`;
        this.cd.detectChanges();
    }
}
